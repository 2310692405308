import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {dangerColor, successColor} from "assets/jss/material-dashboard-react.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.getActions = this.getActions.bind(this);
    }

    getActions(key) {
        const user = this.props.users[key];
        if (user.status == 'PENDING') {
            return (<div>
                        <IconButton aria-label="approve" size="medium" onClick={() => this.props.approveUser(key)}>
                          <CheckCircleOutlineRoundedIcon style={{ color: successColor[2] }}/>
                        </IconButton>
                        <IconButton aria-label="delete" size="medium" onClick={() => this.props.deleteUser(key)}>
                          <CancelOutlinedIcon style={{ color: dangerColor[0] }}/>
                        </IconButton>
                    </div>)
        }
        return (<div>
                  <IconButton aria-label="delete" size="medium" onClick={() => this.props.deleteUser(key)}>
                    <CancelOutlinedIcon style={{ color: dangerColor[0] }}/>
                  </IconButton>
                </div>)
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"role"}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"actions"}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.usersOrder.map((userId, idx) => {
                  return (
                    <TableRow key={userId} className={classes.tableBodyRow}>
                      { Object.values(this.props.tableHead).map((mappingFunction, key) => {
                        return (
                          <TableCell className={classes.tableCell} key={key}>
                            {mappingFunction(this.props.users[userId])}
                          </TableCell>
                        );
                      })}
                      <TableCell className={classes.tableCell} key={"roles-"+userId}>
                        <Select id={"select-roles-"+userId}
                                value={this.props.users[userId].roles}
                                multiple
                                onChange={(event) => this.props.setUserRoles(userId, event.target.value)}
                                fullWidth >
                          <MenuItem value={"ROLE_ADMIN"}>Admin</MenuItem>
                          <MenuItem value={"ROLE_SUFRA"}>Sufra</MenuItem>
                          <MenuItem value={"ROLE_ADVISOR"}>Sufra Advisor</MenuItem>
                          <MenuItem value={"ROLE_BRENT_HUBS"}>Brent Hubs Advisor</MenuItem>
                          <MenuItem value={"ROLE_CASH_PILOT"}>Cash Pilot Administrator</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell className={classes.tableCell} key={"actions"}>
                        {this.getActions(userId)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(UsersTable);

UsersTable.defaultProps = {
  tableHeaderColor: "gray"
};

UsersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
