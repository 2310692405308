import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ReferralsTable from "components/Table/ReferralsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import {formatDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const referralColors = { 'Sufra' : [primaryColor[0], roseColor[0]],
                         'PCC' : [successColor[0], infoColor[0]],
                         'AYP' : [warningColor[0], dangerColor[0]] }
const GUEST_EDITABLE_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

const REFERRAL_EDITABLE_FIELDS = ["referralAgency", "coronavirus", "reason", "groupsMember", "nameOfAuthorisedSignatory", "emailOfAuthorisedSignatory",
    "nappiesCore", "nappiesOther", "toiletriesCore", "toiletriesOther", "babyMilkCore", "babyMilkOther",
    "homelessOrTemporaryAccommodation", "cookingFacilities", "modeOfDelivery", "reasonForDelivery",
    "organisationsSupporting", "furtherInfo", "frequencyOfDelivery", "numberOfDeliveries", "permissionToContact",
    "cannotAffordToPurchaseFood", "appliances"];

class Referrals extends Component {

    constructor(props) {
      super(props);
      this.fetchReferrals = this.fetchReferrals.bind(this);
      this.fetchGuests = this.fetchGuests.bind(this);
      this.setOrderBy = this.setOrderBy.bind(this);
      this.setGuest = this.setGuest.bind(this);
      this.addGuest = this.addGuest.bind(this);
      this.setModeOfDelivery = this.setModeOfDelivery.bind(this);
      this.setCashPilotStatus = this.setCashPilotStatus.bind(this);
      this.setCashPilotGroup = this.setCashPilotGroup.bind(this);
      this.setEligibleForCashPilot = this.setEligibleForCashPilot.bind(this);
      this.setParcelType = this.setParcelType.bind(this);
      this.setCollectionHub = this.setCollectionHub.bind(this);
      this.archiveReferral = this.archiveReferral.bind(this);
      this.acceptReferral = this.acceptReferral.bind(this);
      this.editReferral = this.editReferral.bind(this);
      this.fetchReferral = this.fetchReferral.bind(this);
      this.editGuest = this.editGuest.bind(this);

      this.state = {
        referrals: {},
        referralsOrder: [],
        guests: [],
        numberOfResults: 0,
        orderBy: "timestamp",
        order: "desc"
      };
    }


    componentDidMount() {
        this.fetchReferrals()
        this.fetchGuests()
    }

    fetchReferrals(orderBy = 'timestamp', order = 'desc') {
        ApiService.request(`/referrals/latest?sort=${orderBy},${order}`)
            .then(data => {
                this.setState({referrals: data.content.reduce(function(map, obj) {
                                                                  map[obj.referral.id] = obj;
                                                                  return map;
                                                              }, {}),
                               referralsOrder: data.content.map(referral => referral.referral.id),
                               numberOfResults: data.totalElements})
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchGuests() {
        ApiService.request('/guests/all')
            .then(data => {
                this.setState({guests: data})
            })
            .catch(err => {
                console.log(err);
            });
    }

    setGuest(key, guestId) {
        return ApiService.request(`/referrals/${key}/setGuest?guestId=${guestId || ''}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals});
            })
    }

    addGuest(key) {
        ApiService.request(`/referrals/${key}/addGuest`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                var guests = this.state.guests;
                guests.push(response.guest)
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals, guests: guests});
            })
    }

    setCollectionHub(key, collectionHub) {
        ApiService.request(`/referrals/${key}/setCollectionHub?collectionHub=${collectionHub}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    setModeOfDelivery(key, modeOfDelivery) {
        ApiService.request(`/referrals/${key}/setModeOfDelivery?modeOfDelivery=${modeOfDelivery}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    setCashPilotStatus(key, cashPilotStatus) {
        ApiService.request(`/referrals/${key}/setCashPilotStatus?cashPilotStatus=${cashPilotStatus}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    setEligibleForCashPilot(key, eligibleForCashPilot) {
        ApiService.request(`/referrals/${key}/setEligibleForCashPilot?eligibleForCashPilot=${eligibleForCashPilot}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    setCashPilotGroup(key, cashPilotGroup) {
        ApiService.request(`/referrals/${key}/setCashPilotGroup?cashPilotGroup=${cashPilotGroup}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    setParcelType(key, parcelType) {
        ApiService.request(`/referrals/${key}/setParcelType?parcelType=${parcelType}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id].referral = response;
                this.setState({ referrals: referrals })
            })
    }

    getCsv() {
        ApiService.requestCsv('/referrals/latest/csv')
    }

    archiveReferral(key, archiveReason, archiveComment, archiveDate) {
        return ApiService.request(`/referrals/${key}/archive?archiveReason=${archiveReason}&archiveDate=${formatDate(archiveDate || new Date())}`, 'PUT', archiveComment)
            .then(() => {
                this.fetchReferrals(this.state.orderBy, this.state.order)
            })
    }

    acceptReferral(key) {
        ApiService.request(`/referrals/${key}/accept`, 'PUT')
            .then(() => {
                this.fetchReferrals(this.state.orderBy, this.state.order)
            })
    }

    editReferral(referral) {
        var referralToSend = REFERRAL_EDITABLE_FIELDS.reduce(function (result, field) {
            result[field] = referral[field];
            return result;
        }, {});
        return ApiService.request(`/referrals/${referral.id}`, 'PUT', referralToSend)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[referral.id].referral = data;
                this.setState({referrals: referrals});
            })
            .catch(err => {
                console.log(err);
            });
    }

    editGuest(referralId, guest) {
        var guestToSend = GUEST_EDITABLE_FIELDS.reduce(function (result, field) {
            result[field] = guest[field];
            return result;
        }, {});
        return ApiService.request(`/guests/${guest.id}`, 'PUT', guestToSend)
            .then(data => {
                var referrals = this.state.referrals;
                var guests = this.state.guests;
                guests[guests.findIndex(el => el.id === guest.id)] = data;
                referrals[referralId].referral.guest = data;
                this.setState({referrals: referrals, guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchReferral(id) {
        return ApiService.request(`/referrals/${id}`)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[id].referral = data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    setOrderBy(prop) {
        const isAsc = this.state.orderBy === prop && this.state.order === 'asc';
        this.setState({order: isAsc ? 'desc' : 'asc', orderBy: prop});
        this.fetchReferrals(prop, isAsc ? 'desc' : 'asc')
    }

    getMarker(isHub, name, hub) {
      return "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + name.charAt(0).toUpperCase() + "|" + referralColors[hub][isHub ? 0 : 1].substring(1)
    }

    render() {
        const { classes } = this.props;
        return (
          <GridContainer>
            <GridItem md={11} style={{minHeight: '400px', maxHeight: '400px'}}>
              <Map google={this.props.google} zoom={15} initialCenter={{lat: 51.548969, lng: -0.266440}} style={{height: '400px'}} mapTypeId="hybrid">
                {this.props.collectionHubs.filter(hub => hub.active).map((hub, idx) => {
                   return (<Marker position={hub.location} title={hub.name} name={hub.name} icon={{url: this.getMarker(true, hub.name, hub.name)}}/>)
                })}
                {this.state.referralsOrder.map((id, idx) => {
                  var referral = this.state.referrals[id].referral;
                  return referral.lat && (<Marker position={referral} title={referral.fullName} name={referral.fullName}  icon={{url: this.getMarker(false, referral.fullName, referral.collectionHub)}}/>)
                })}
              </Map>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <GridContainer alignItems='center'>
                      <GridItem md={11}>
                          <h3 className={classes.cardTitleWhite}>Referrals</h3>
                          <p className={classes.cardCategoryWhite}>Showing {this.state.numberOfResults} referrals</p>
                      </GridItem>
                      <GridItem md={1}>
                        <Tooltip title="Download as CSV">
                          <IconButton aria-label="csv" size="large" onClick={() => this.getCsv()}>
                            <CloudDownloadOutlinedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <ReferralsTable
                    tableHeaderColor="primary"
                    tableHead={{"id": "ID",
                                "timestamp": "Timestamp",
                                "referralAgency": "Referral Agency",
                                "fullName": "Full Name",
                                "contactNumber": "Contact Number",
                                "address": "Address",
                                "postcode": "Postcode",
                                "numberOfPeople": "Number of people"}}
                    mappingFunctions={{"id": referralGuestPair => referralGuestPair.referral.id,
                                       "timestamp": referralGuestPair => referralGuestPair.referral.timestamp,
                                       "referralAgency": referralGuestPair => referralGuestPair.referral.referralAgency,
                                       "fullName": referralGuestPair => (referralGuestPair.referral.guest || referralGuestPair.referral).fullName,
                                       "contactNumber": referralGuestPair => referralGuestPair.referral.guest && referralGuestPair.referral.guest.contactNumbers.toString() || referralGuestPair.referral.contactNumber,
                                       "address": referralGuestPair => (referralGuestPair.referral.guest || referralGuestPair.referral).address,
                                       "postcode": referralGuestPair => (referralGuestPair.referral.guest || referralGuestPair.referral).postcode,
                                       "numberOfPeople": referralGuestPair => (referralGuestPair.referral.guest || referralGuestPair.referral).numberOfPeople}}
                    referralsOrder={this.state.referralsOrder}
                    referrals={this.state.referrals}
                    guests={this.state.guests}
                    collectionHubs={this.props.collectionHubs}
                    setGuest={this.setGuest}
                    addGuest={this.addGuest}
                    setModeOfDelivery={this.setModeOfDelivery}
                    setCashPilotStatus={this.setCashPilotStatus}
                    setCashPilotGroup={this.setCashPilotGroup}
                    setCashPilotEligible={this.setCashPilotEligible}
                    setParcelType={this.setParcelType}
                    setCollectionHub={this.setCollectionHub}
                    archiveReferral={this.archiveReferral}
                    acceptReferral={this.acceptReferral}
                    editReferral={this.editReferral}
                    fetchReferral={this.fetchReferral}
                    orderBy={this.state.orderBy}
                    order={this.state.order}
                    setOrderBy={this.setOrderBy}
                    editGuest={this.editGuest}
                    getOptions={this.props.getOptions}
                    addOption={this.props.addOption}
                    user={this.props.user}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(Referrals))
